<template>
  <v-container fluid>
    <shipmentDocList v-if="showScreen" />
  </v-container>
</template>
<script>
import _ from "lodash";
import Swal from "sweetalert2";
import shipmentDocList from "./shipmentDocList";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import Loading from "vue-loading-overlay";

export default {
  mixins: [checkPermissionsMixin],
  components: {
    Loading,
    shipmentDocList,
  },
  created() {
    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });
  },
  data() {
    return {
      showScreen: true,
      items: {
        list: [],
      },
      loading: true,
      search: "",
      payload: {
        skip: "0",
        limit: "15",
      },
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    startLoad() {
      this.loading = true;
    },
    stopLoad() {
      this.loading = false;
    },

    load(event) {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: #484848;
  font-weight: bold;
}

td {
  font-size: 16px !important;
}

thead {
  background: #efefef;
}

.track-btn {
  background-color: #ffeeda !important;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.p-16 {
  padding: 0 16px !important;
}
.pull--right {
  float: right;
}
.color-orange {
  color: darkorange !important;
  font-size: 25px;
  padding-top: 15px;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
