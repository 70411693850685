<template>
  <v-card class="nospace">
    <loading :active.sync="loading" />
    <v-card-title class="bg-clr" style="height: 80px; padding: 16px !important">
      <v-layout wrap>
        <v-flex>
          <span class="color-orange breakword"
            >Shipment Document Management</span
          >
        </v-flex>
        <v-flex style="text-align: end">
          <addShipmentDoc />
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text class="nospace pt-5" style="padding-top: 48px !important">
      <v-data-table
        id="ListTable"
        hide-actions
        :headers="headers"
        :items="items"
        style="word-break: break-all"
      >
        <template slot="items" slot-scope="props">
          <td>
            {{ props.item.documentName }}<br />
            <!-- <ActivityLogs  :user="props.item.documentNumber" /> -->
            <activityLogs
              :type="'shipmentType'"
              :id="props.item.documentNumber.toString()"
            />
          </td>
          <td style="word-break: initial">
            {{ getType(shipmentTypes, props.item.jobType) }}
          </td>
          <td>{{ checkWorkingCountry(props.item.country) }}</td>
          <td style="word-break: initial">
            {{ getTypeArray(jobGeoTypes, props.item.jobGeoType) }}
          </td>
          <td style="word-break: initial">
            {{ getTypeArray(jobBookingTypes, props.item.bookingType) }}
          </td>
          <td style="word-break: initial">
            {{ getTypeArray(jobModes, props.item.jobMode) }}
          </td>
          <td style="word-break: initial">
            {{ props.item.stakeHolder.toString() }}
          </td>
          <td>{{ getTime3(props.item.createdAt) }}</td>
          <td>
            <v-flex class="pl-4" v-if="props.item.isDeleted">
              <span>Deleted</span>
            </v-flex>

            <button
              v-if="!props.item.isDeleted"
              @click="getDocNumber(props.item.documentNumber)"
              target="_blank"
              style="white-space: nowrap"
              class="new-btn red-button"
              :disabled="loading"
            >
              Delete
            </button>

            <editShipmentDoc
              v-if="!props.item.isDeleted"
              :documentNumber="props.item.documentNumber"
            />
          </td>
        </template>
      </v-data-table>

      <!-- <span class="page-count-span totalpages"
        >Total Pages - {{ totalPages }}</span
      > -->
      <pagination
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :url="`/dmsAdmin/listOfShipmentType`"
        :callback="callback"
        :componentKey="componentKey"
        :action="`shipmentDoc`"
        :payload="payload"
        :type="1"
        v-on:pagination-load="checkLoading"
      />

      <!-- <PaginationButtons
        :url="`/dmsAdmin/listOfShipmentType`"
        :payload="payload"
        :callback="callback"
        :componentKey="componentKey"
        v-on:pagination-payload="paginationPayload"
      /> -->
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
      <v-dialog
        v-model="deleteDialog"
        persistent
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
      >
        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title style="color: black">{{
              deleteTitle
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              :disabled="loading"
              @click.prevent="deleteDialog = false"
            >
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text v-show="!!deleteMessage" class="pa-4">
            <v-layout>
              <span class="heading grey--text text--darken-4">{{
                deleteMessage
              }}</span>
            </v-layout>
            <v-layout class="text--darken-4" pt-2>
              <span
                >This shipment will no longer be accessible once you delete it
                from the list</span
              >
            </v-layout>
          </v-card-text>

          <v-card-actions style="padding-bottom: 20px">
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              :disabled="loading"
              flat
              @click="deleteDialog = false"
              >NO</v-btn
            >
            <v-btn
              color="orange darken-1"
              :disabled="loading"
              flat
              @click="deleteShipmentType()"
              >YES</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
import { shipmentDocist } from "@/constants/datatable-headers.js";
import activityLogs from "../Common/activityLogs";
import SuccessDialog from "@/components/Common/SuccessDialog";
import addShipmentDoc from "./addShipmentDoc";
import editShipmentDoc from "./editShipmentDoc";
import ErrorBox from "@/components/Common/ErrorBox";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import pagination from "@/components/Pagination/pagination";
import removeLocalstorageMixin from "@/mixins/removeLocalstorageMixin";
import {
  deleteShipmentType,
  verifyShipmentDocument,
  rejectShipmentDocument,
} from "../../constants/api-urls";
import moment from "moment";
import {
  StorageKeys,
  JobTypes,
  JobGeoType1,
  JobBookingType1,
  JobMode1,
  CancellationType,
} from "../../constants/constants";
import { async } from "q";
export default {
  created() {
    this.$eventBus.$on("stop-load", () => {
      this.loading = false;
    });
    this.$eventBus.$on("edit-shipment-doc", () => {
      this.componentKey += 1;
    });
    this.$eventBus.$on("add-shipment-doc", () => {
      this.componentKey += 1;
    });
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      this.payload.workingCountry = this.workingCountry;
    });
    this.loading = false;
    this.payload = {
      workingCountry: this.workingCountry,
    };
    this.cancellationType = CancellationType;
    this.shipmentTypes = JobTypes;
    this.jobGeoTypes = JobGeoType1;
    this.jobModes = JobMode1;
    this.jobBookingTypes = JobBookingType1;
    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }
  },
  components: {
    pagination,
    SuccessDialog,
    ErrorBox,
    addShipmentDoc,
    editShipmentDoc,
    activityLogs,
    Loading,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,
  },

  data() {
    return {
      workingCountry: null,
      docNumber: "",
      options: {
        color: "rgb(245, 245, 245)",
        width: 600,
        zIndex: 200,
      },
      deleteDialog: false,
      deleteTitle: "Delete Shipment document",
      deleteMessage: "Are you sure want to delete this?",
      shipmentTypes: [],
      countrySortName: [],
      multipleCountry: [
        { value: "KSA", currency: "SAR" },
        { value: "UAE", currency: "AED" },
        { value: "PAK", currency: "PKR" },
        { value: "UAE LA", currency: "AED" },
        { value: "OMAN", currency: "OMR" },
      ],
      jobBookingTypes: [],
      stakeholders: [],
      jobModes: [],
      pages: 1,
      jobGeoTypes: [],
      cancellationType: [],
      cancellationReason: "",
      cancelType: "",
      error: "",
      cancelDialog: false,
      cancelreason: "",
      loading: true,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,

      totalPages: 0,

      payload: {},

      headers: [
        {
          text: "Shipment Type",
          sortable: false,
          value: "Name",
          class: "table-header-item",
        },
        {
          text: "Job Type",
          sortable: false,
          value: "type",
          class: "table-header-item",
        },
        {
          text: "Working Country",
          sortable: false,
          value: "country",
          class: "table-header-item",
        },
        {
          text: "Geography",
          sortable: false,
          value: "geography",
          class: "table-header-item",
        },
        {
          text: "Booking Type",
          sortable: false,
          value: "bookingType",
          class: "table-header-item",
        },
        {
          text: "Job Mode",
          sortable: false,
          value: "jobMode",
          class: "table-header-item",
        },
        {
          text: "Stakeholder",
          sortable: false,
          value: "stakeholder",
          class: "table-header-item",
        },
        {
          text: "Created on",
          sortable: false,
          value: "createdon",
          class: "table-header-item",
        },
        {
          text: "Action",
          sortable: false,
          value: "action",
          class: "table-header-item",
        },
      ],
      items: [],
    };
  },
  watch: {},
  methods: {
    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        // this.$emit("job-load", false);
        this.loading = false;
      }
    },
    page(event) {
      this.pages = event;
    },

    checkWorkingCountry(country) {
      var workingCountryArray = [];
      JSON.parse(this.workingCountry).forEach((key, index) => {
        if (country && country.includes(key)) {
          workingCountryArray.push(key);
        }
      });
      return workingCountryArray.toString();
    },
    getDocNumber(number) {
      this.docNumber = number;
      this.deleteDialog = true;
    },
    deleteShipmentType() {
      this.loading = true;
      this.processing = true;
      let Url = deleteShipmentType;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        documentNumber: this.docNumber,
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.loading = false;
          this.deleteDialog = false;
          this.componentKey += 1;
        },
        (error) => {
          this.x.success = false;
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getTime3(date) {
      return moment.utc(date).format("ll");
    },
    getTypeArray(arr = [], val) {
      let finalEl = arr;
      let temp = [];

      for (var i = 0; i < arr.length; i++) {
        if (val.indexOf(arr[i].value) == -1) {
        } else {
          temp.push(arr[i].text);
        }
      }
      return temp.toString();
    },
    getType(arr = [], val) {
      let finalEl = arr

        //    for (var i = 0; i < val.length; i++) {
        //           arr.filter((x) => val[i] !== undefined)
        //     .find((el) => el.value === val);
        //   return finalEl ? finalEl.text : "NA";

        // }
        .filter((val) => val !== undefined)
        .find((el) => el.value === val);
      return finalEl ? finalEl.text : "NA";
    },
    open(assignmentId, _id) {
      this.cancelDialog = true;
      this.id = assignmentId;
      this.assignmentId = _id;
    },
    closeAdvance() {
      this.cancelDialog = false;
      this.cancelreason = "";
    },
    verified(assignmentId, _id) {
      this.loading = true;
      let Url = verifyShipmentDocument;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        id: _id,
        assignmentId: assignmentId,
        isVerify: true,
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.x.success = true;
          this.loading = false;
        },
        (error) => {
          this.success = false;
          this.loading = false;
          this.error = error.response.data.message;
          setTimeout(() => {
            this.error = "";
          }, 6000);
        }
      );
    },
    reject() {
      this.loading = true;
      if (!this.cancelType) {
        this.error = "Please select cancellation reason before moving ahead";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        this.loading = false;
        return false;
      }
      if (this.cancelType.value == "Other’s") {
        if (!this.cancellationReason) {
          this.error = "Please enter cancellation reason before moving ahead";
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          this.loading = false;
          return false;
        }
      }
      this.processing = true;
      let Url = rejectShipmentDocument;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        id: this.assignmentId,
        assignmentId: this.id,
        isReject: true,
      };
      if (this.cancelType.value) {
        if (this.cancelType.value == "Other’s") {
          body.cancelReason =
            this.cancelType.value + " - " + this.cancellationReason;
        } else {
          body.cancelReason = this.cancelType.value;
        }
      }
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.x.success = true;
          this.loading = false;
        },
        (error) => {
          this.x.success = false;
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
    },

    callback(res) {
      // this.loading = false;
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);
      window.scrollTo(0, 0);
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
#ListTable .theme--light.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#ListTable .theme--light.v-datatable thead th.column.sortable.active .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.new-btn {
  text-decoration: none;
  color: #ffffff;
  border-radius: 4px;
  background-color: darkorange;
  border: 2px solid darkorange;
  padding: 4px 15px;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
</style>
